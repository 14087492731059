import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import Layout from '../components/layout'

/* Component */
const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = useSiteMetadata().title
  const {
    body,
    excerpt,
    frontmatter: { title, date, featuredImage, description },
  } = post

  return (
    <Layout location={location}>
      <SEO
        title={`${title} | ${siteTitle}`}
        description={description || excerpt}
      />
      <ImageContainer>
        <Image alt={title} fluid={featuredImage.childImageSharp.fluid} />
        <ImageText>{title}</ImageText>
      </ImageContainer>
      <DateText>{date}</DateText>
      <BodyTextWrapper>
        <MDXRenderer>{body}</MDXRenderer>
      </BodyTextWrapper>
    </Layout>
  )
}

export default BlogPostTemplate

/* Page Query for data */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      id
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

/* Styling */
const BodyTextWrapper = styled.div`
  ${fluidRange(
    { prop: 'font-size', fromSize: '18px', toSize: '24px' },
    '375px',
    '1200px'
  )}
`
const ImageContainer = styled.div`
  max-height: 600px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
`
const Image = styled(Img)`
  max-height: inherit;
  filter: brightness(0.6) blur(2px);
`
const ImageText = styled.div`
  ${fluidRange(
    { prop: 'font-size', fromSize: '32px', toSize: '120px' },
    '375px',
    '1200px'
  )}
  position: absolute;
  text-align: center;
  padding: 2% 5%;
  left: 10%;
  right: 10%;
  top: 50%;
  font-weight: 900;
  transform: translate(0%, -50%);
  z-index: 1;
  color: white;
  line-height: 1.2;
  font-variant: small-caps;
`
const DateText = styled.div`
  padding-top: 10px;
  color: ${(props) => props.theme.gray};
`
